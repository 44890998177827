export const questionnaire = [
  {
    id: 1,
    question: "What best describes you? ",
    type: "singleselect",
    layout: "gridsmall",
    options: [
      {
        id: 1,
        value: "a",
        label: "I am interested in CycleSaver’s products for myself.",
        selected: false,
        img: "user.png",
      },
      {
        id: 2,
        value: "b",
        label: "I am interested in offering CycleSaver’s product to my company’s employees.",
        selected: false,
        img: "group.png",
      },
      // { id: 3, value: "c", label: "Other (specify)", type: "manual", selected: false },
    ],
  },
  {
    id: 2,
    conditionQID: 1,
    conditionAnswerIDs: [1, 3],
    question: "Which of the following bike share schemes have you used in the past 12 months?",
    type: "multiselect",
    layout: "grid",

    options: [
      { id: 4, value: "d", label: "Lime", selected: false, img: "lime.png" },
      { id: 1, value: "a", label: "Santander Cycles", selected: false, img: "santander.png" },
      { id: 3, value: "c", label: "Forest", selected: false, img: "forest.jpeg" },
      { id: 2, value: "b", label: "Dott", selected: false, img: "dott.jpg" },
      { id: 6, value: "f", label: "Voi", selected: false, img: "voi.png" },
      { id: 5, value: "e", label: "Beryl", selected: false, img: "beryllogo.png" },
      { id: 7, value: "g", label: "None - I haven’t used any.", selected: false, effect: "clear_others" },
    ],
  },
  // {
  //   id: 3,
  //   conditionQID: 2,
  //   conditionAnswerIDs: [1, 2, 3, 4, 5, 6],
  //   question: "Which is your preferred provider?",
  //   type: "singleselect",
  //   layout: "grid",

  //   options: [
  //     { id: 1, value: "a", label: "Santander Cycles", selected: false, img: "santander.png" },
  //     { id: 2, value: "b", label: "TIER", selected: false, img: "tier.jpeg" },
  //     { id: 3, value: "c", label: "Forest", selected: false, img: "forest.jpeg" },
  //     { id: 4, value: "d", label: "Lime", selected: false, img: "lime.png" },
  //     { id: 5, value: "e", label: "Beryl", selected: false, img: "beryllogo.png" },
  //     { id: 6, value: "f", label: "Nextbike", selected: false, img: "nextbike.png" },
  //     { id: 7, value: "g", label: "None - I haven’t used any.", selected: false, effect: "clear_others" },
  //   ],
  // },
  {
    id: 3,
    conditionQID: 1,
    conditionAnswerIDs: [1, 3],
    layout: "list",

    question: "How often do you cycle? (for travel commuting, not fitness)",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Frequently (4 or more days per week)", selected: false },
      { id: 2, value: "b", label: "Sometimes (1-3 days per week)", selected: false },
      { id: 3, value: "c", label: "Rarely (<1 day per week)", selected: false },
      { id: 4, value: "d", label: "Never", selected: false },
    ],
  },
  {
    id: 4,
    layout: "gridsmall",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    type: "singleselect",

    question: "90% of CycleSaver customers already have a 'traditional' cycle to work scheme. Does your company have a 'traditional' cycle to work scheme? ",
    options: [
      {
        id: 1, value: "a", label: "Yes", selected: false, img: "tick.png",
      },
      { id: 2, value: "b", label: "No", selected: false, img: "cross.png" },
    ],
  },
  {
    id: 5,
    layout: "widegrid",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    iconsLarge: true,
    question: "Are you aware of bike sharing schemes? Select the ones that you recognise.",
    type: "multiselect",
    options: [
      { id: 4, value: "d", label: "Lime", selected: false, img: "bikesLime.png" },
      { id: 1, value: "a", label: "Santander Cycles", selected: false, img: "bikesSantander.png" },
      { id: 3, value: "c", label: "Forest", selected: false, img: "bikesForest.png" },
      { id: 2, value: "b", label: "Dott", selected: false, img: "bikesDott.png" },
      { id: 6, value: "f", label: "Voi", selected: false, img: "bikesVoi.png" },
      { id: 5, value: "e", label: "Beryl", selected: false, img: "bikesBeryl.png" },
      { id: 7, value: "g", label: "None", selected: false, effect: "clear_others" },
    ],
  },
  {
    id: 6,
    layout: "gridsmall",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question:
      "Are you aware that cycle to work/salary sacrifice benefits (28% - 47% savings) are available for employees who prefer using these bike sharing providers?",
    type: "singleselect",
    options: [
      {
        id: 1, value: "a", label: "Yes", selected: false, img: "tick.png",
      },
      { id: 2, value: "b", label: "No", selected: false, img: "cross.png" },
    ],
  },
  {
    id: 7,
    layout: "list",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question:
      "What appeals most about CycleSaver's salary sacrifice bike share subscriptions?",
    type: "multiselect",
    options: [
      {
        id: 1, value: "a", label: "Employee financial Savings (up to 47%)", selected: false,
      },
      {
        id: 2, value: "b", label: "Flexibility - monthly subscriptions, cancel anytime", selected: false
      },
      {
        id: 3, value: "c", label: "Cost neutral for companies", selected: false,
      },
      {
        id: 4, value: "d", label: "Encouraging sustainable travel", selected: false
      },
      {
        id: 5, value: "e", label: "Health benefits", selected: false,
      },
      {
        id: 6, value: "f", label: "None of the above ", selected: false, effect: "clear_others"
      },
    ],
  },
  {
    id: 8,
    layout: "widegrid",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question: "Which Employee Benefit platform do you use? ",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Benefex", selected: false, img: "benefex.jpg" },
      { id: 2, value: "b", label: "Caboodle", selected: false, img: "caboodle.jpeg" },
      { id: 3, value: "c", label: "Darwin", selected: false, img: "darwin.jpeg" },
      { id: 4, value: "d", label: "Reward Gateway", selected: false, img: "rewardgateway.jpeg" },
      { id: 5, value: "e", label: "Zest Benefits", selected: false, img: "zest.png" },
      { id: 6, value: "f", label: "Ben", selected: false, img: "ben.webp" },
      { id: 7, value: "g", label: "Zhoosh", selected: false, img: "zhoosh.png" },
      { id: 8, value: "h", label: "Vivup", selected: false, img: "vivup.png" },
      { id: 9, value: "i", label: "Barnett Waddingham", selected: false, img: "barnettwaddingham.jpg" },
      { id: 10, value: "j", label: "Amba", selected: false, img: "amba.svg" },
      { id: 11, value: "k", label: "Benify", selected: false, img: "benify.png" },
      { id: 12, value: "l", label: "Other (please specify)", type: "manual", selected: false },
      { id: 13, value: "m", label: "None", selected: false, effect: "clear_others" },
    ],
  },

  {
    id: 9,
    question: "Last step before you spin to win! 🤩",
    type: "form",
    layout: "list",
  },
];
