import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { TextField, MenuItem, Button, FormControl, FormHelperText, Box, Typography, Paper, Modal } from "@mui/material";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { SettingsEthernet } from "@material-ui/icons";
import { DateTime } from "luxon";
import { questionnaire } from "./structure";
import { Wheel } from "react-custom-roulette";
import useSound from "use-sound";
import useWindowSize from "react-use/lib/useWindowSize";
import VideoPlayer from "../../components/VideoPlayer";
import { makeStyles } from "@mui/styles";

import Confetti from "react-confetti";
import ReactDOM from "react-dom";
const sound = require("../../assets/karamela.mp3");
const achievement = require("../../assets/achievement.mp3");
const prizes = [
  { option: "Bag", imgPrefix: "" },
  { option: "Voucher", imgPrefix: "" },

  { option: "4x Vouchers", imgPrefix: "4X" },
  { option: "Voucher", imgPrefix: "" },

  { option: "2x Vouchers", imgPrefix: "2X" },

  { option: "Voucher", imgPrefix: "" },

  { option: "4x Vouchers", imgPrefix: "4X" },

  { option: "Voucher", imgPrefix: "" },
];

const vouchers = [
  {
    voucherTypeID: "HUMN10MIN",
    description: "10 minute ride voucher",
    provider: "Forest",
    img: "forest.jpeg",
  },
  {
    voucherTypeID: "BERYL15MIN",
    description: "15 minute ride voucher",
    provider: "Beryl",
    img: "beryllogo.png",
  },
  {
    voucherTypeID: "SANTCYC030",
    description: "30 minute ride voucher",
    provider: "TfL Santander Cycles",
    img: "santander.png",
  },
];

const Form = () => {
  const [currentStep, setCurrentStep] = useState(-1);
  const [showPrize, setShowPrize] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const initialFormData = {
    name: "",
    email: "",
    companyName: "",
    phone: "",
    tcs: false,
    selectedVoucher: {},
  };

  const [selectedVoucher, setSelectedVoucher] = useState<any>();
  const [formData, setFormData] = useState<any>(initialFormData);
  const [activeQuestionnaire, setActiveQuestionnaire] = useState<any>(questionnaire);
  const [navigationDirection, setNavigationDirection] = useState<string>("forward");
  const [errors, setErrors] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);

  const validateForm = () => {
    console.log(formData);
    let newErrors: any = {};
    // Mandatory fields
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,20}$/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.companyName) {
      newErrors.companyName = "Company name is required.";
    }

    if (!selectedVoucher?.voucherTypeID) {
      newErrors.selectedVoucher = "Please select a voucher.";
    }

    if (!formData.tcs) {
      newErrors.tcs = "Please accept the terms and conditions.";
    }

    // Set errors or return true if no errors
    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error when the field is corrected
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const storePrize = (prize: number) => {
    var body = {
      prizeIndex: prize,
      prizeList: prizes,
      prize: prizes[prize].option,
      email: formData.email,
      voucherTypeID: selectedVoucher.voucherTypeID,
    };

    axios
      .post(process.env.REACT_APP_FUNCTIONS_URL + "/updatePrize", body)
      .then((response) => {
        // Handle success
        console.log("Stored prize");
        setCurrentStep(100);
        setSubmitting(false);

        // Optionally reset form or set success state
      })
      .catch((error) => {
        // Handle error

        storeFailedPrize(body);
        // setCurrentStep(100);
        // setSubmitting(false);
      });
  };

  const storeFailedPrize = (body: any) => {
    let submissions = JSON.parse(localStorage.getItem("pendingSubmissions") || "[]");
    if (submissions.length > 0) {
      console.log("Found all submissions");
      var sub = submissions.find((s: any) => s.email != body.email);
      if (sub) {
        console.log("Updating matching sub");
        sub.prizeData = body;
        var updatedSubList = submissions.map((s: any) => {
          if (s.respondent.email == body.email) {
            console.log("Found matching sub to replace in list");
            return sub;
          } else return s;
        });
        console.log("setting new list");
        localStorage.setItem("pendingSubmissions", JSON.stringify(updatedSubList));
      }
    }
  };

  const handleSubmit = (e: any) => {
    setSubmitting(true);
    e.preventDefault();
    if (!validateForm()) {
      console.log("ERROR");
      setSubmitting(false);
      return;
    }

    const finalData = {
      responses: activeQuestionnaire.filter((q: any) => showQuestion(q)).filter((q: any) => q.type != "form"),
      respondent: { ...formData, selectedVoucher: selectedVoucher },
      type: "REAL_TIME",
      dateTimeSubmitted: DateTime.now().toISO(),
    };
    console.log(finalData);
    axios
      .post(process.env.REACT_APP_FUNCTIONS_URL + "/submitInterestForm", finalData)
      .then((response) => {
        // Handle success
        toast("Your submission has been processed!", { type: "success" });
        console.log("Form submitted successfully", response.data);
        setCurrentStep(100);
        setSubmitting(false);

        // Optionally reset form or set success state
      })
      .catch((error) => {
        // Handle error

        console.log("Submission error - queuing for retry later for " + finalData.respondent.email, error);
        storeFailedSubmission(finalData);
        setCurrentStep(100);
        setSubmitting(false);
      });
  };

  async function storeFailedSubmission(data: any) {
    let submissions = JSON.parse(localStorage.getItem("pendingSubmissions") || "[]");
    submissions.push(data);
    localStorage.setItem("pendingSubmissions", JSON.stringify(submissions));
    toast("Your submission has been saved!", { type: "success" });
  }

  async function retryFailedSubmissions() {
    console.log(new Date().toISOString() + " Running submission sync logic...");
    let submissions = JSON.parse(localStorage.getItem("pendingSubmissions") || "[]");
    console.log("Found " + submissions.length + " submissions");

    for (let i = submissions.length - 1; i >= 0; i--) {
      // Update the type for each submission
      const data = { ...submissions[i], type: "BATCH" };

      try {
        const response = await axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/submitInterestForm`, data);
        console.log("Resubmitted successfully", response.data);

        // Fetch the latest list from localStorage in case it has been updated
        submissions = JSON.parse(localStorage.getItem("pendingSubmissions") || "[]");
        // Remove the successful submission from the array
        const newSubmissions = submissions.filter((_: any, index: number) => index !== i);
        localStorage.setItem("pendingSubmissions", JSON.stringify(newSubmissions));

        // Update submissions to the new list after removal
        submissions = newSubmissions;
      } catch (error) {
        console.log("Resubmission error", error);
        // Don't remove the submission; just proceed to the next
      }
      // Wait a bit before the next submission (e.g., 5 seconds)
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  const nextStep = (qlist?: any) => {
    console.log(qlist, activeQuestionnaire);
    const qs = qlist ?? activeQuestionnaire;
    const activeQs = qs.filter((q: any) => showQuestion(q));
    if (currentStep != -1 && !activeQs[currentStep].options.find((o: any) => o.selected == true)) {
      toast.warning("Please select an option to proceed.");
      return;
    }
    if (currentStep < activeQs.length - 1) {
      setNavigationDirection("forward");
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
      }, 50);
    } else {
      console.log("END OF QUESTIONS");
      console.log(qs);
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > -1) {
      setNavigationDirection("backward");

      setTimeout(() => {
        setCurrentStep(currentStep - 1);
      }, 100);
    }
  };

  const startAgain = () => {
    setNavigationDirection("backward");
    setShowConfetti(false);
    setShowPrize(false);
    setSelectedVoucher(undefined);
    setTimeout(() => {
      setCurrentStep(-1);
      setActiveQuestionnaire(questionnaire);
      setFormData(initialFormData);
    }, 300);
  };

  // Define animation
  const [variants, setVariants] = useState({
    initial: { opacity: 0, x: 200 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -200 },
  });

  useEffect(() => {
    console.log("Setting to " + navigationDirection);
    setVariants({
      initial: { opacity: 0, x: navigationDirection == "forward" ? 200 : -200 },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: navigationDirection == "forward" ? -200 : 200 },
    });
  }, [navigationDirection]);

  useEffect(() => {
    retryFailedSubmissions();
    const intervalId = setInterval(retryFailedSubmissions, 600000); // Retry every 10 minutes, adjust as necessary

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  const BackButton = () => {
    return currentStep > 0 && currentStep != 100 ? (
      <p
        className="top-3 left-5 absolute bg-grey/80 p-1 md:p-3 rounded-md items-center justify-center flex flex-row gap-2"
        onClick={previousStep}
      >
        <img src={require("../../assets/back.png")} className="w-5 h-5" />
      </p>
    ) : null;
  };

  const StartOver = () => {
    return currentStep > 0 ? (
      <p
        className="top-3 right-5 absolute bg-grey/80 p-1 md:p-3 rounded-md items-center justify-center flex flex-row gap-2"
        onClick={startAgain}
      >
        <img src={require("../../assets/restart.png")} className="w-5 h-5" />
        Start Again
      </p>
    ) : null;
  };

  const StepsLeft = () => {
    return currentStep > 0 ? (
      <p
        className="top-3 right-600 absolute bg-grey/80 p-1 md:p-3 rounded-md items-center justify-center flex flex-row gap-2"
      // onClick={startAgain}
      >
        {/* <img src={require("../../assets/restart.png")} className="w-5 h-5" /> */}
        {currentStep + 1} / {activeQuestionnaire.filter((q: any) => showQuestion(q)).length}
      </p>
    ) : null;
  };

  const showQuestion = (q: any) => {
    if (q.conditionQID) {
      //There's a condition on whether to show this question
      // If the question has a condition
      const conditionQuestion = activeQuestionnaire.find((c: any) => c.id === q.conditionQID);
      //Find the question we depend on

      if (conditionQuestion) {
        //Found the question we depend on
        const conditionAnswer = conditionQuestion.options?.find(
          (o: any) => q.conditionAnswerIDs.includes(o.id) && o.selected
        );
        //Filter the options of that question
        if (conditionAnswer) {
          return conditionAnswer.selected;
        }
      }
      return false;
    }
    return true;
  };

  const classes = useStyles();

  const handleOptionSelection = (qid: any, oid: any, text?: string) => {
    const updatedQuestionnaire = activeQuestionnaire.map((question: any) => {
      if (question.id === qid) {
        if (question.type === "singleselect") {
          return {
            ...question,
            options: question.options?.map((option: any) => {
              if (option.id === oid) {
                if (option.type == "manual") {
                  var obj = {
                    ...option,
                    selected: true,
                    textResponse: text,
                  };
                  return obj;
                } else {
                  return {
                    ...option,
                    selected: true,
                  };
                }
              } else {
                delete option.textResponse;
                return {
                  ...option,
                  selected: false,
                };
              }
            }),
          };
        } else {
          if (question.options.find((o: any) => o.id == oid)?.effect == "clear_others") {
            return {
              ...question,
              options: question.options?.map((option: any) => {
                if (option.id === oid) {
                  return {
                    ...option,
                    selected: !option.selected,
                  };
                } else {
                  return {
                    ...option,
                    selected: false,
                  };
                }
              }),
            };
          } else {
            return {
              ...question,
              options: question.options?.map((option: any) => {
                if (option.id === oid) {
                  if (option.type == "manual") {
                    if (text == "" || !text) {
                      console.log("HEre text is ", text);
                      return {
                        ...option,
                        selected: false,
                        textResponse: "",
                      };
                    } else {
                      var obj = {
                        ...option,
                        selected: true,
                        textResponse: text,
                      };
                      return obj;
                    }
                  }
                  return {
                    ...option,
                    selected: !option.selected,
                  };
                } else if (option.effect == "clear_others") {
                  return { ...option, selected: false };
                }
                return option;
              }),
            };
          }
        }
      }
      return question;
    });

    setActiveQuestionnaire(updatedQuestionnaire);
    console.log(
      "Moving to next",
      updatedQuestionnaire.find((q: any) => q.id == qid).type === "singleselect",

      updatedQuestionnaire.find((q: any) => q.id == qid).options.find((o: any) => o.id == oid)?.type != "manual",
      updatedQuestionnaire.find((q: any) => q.id == qid).id,
      qid,
      oid
    );

    if (
      activeQuestionnaire.find((q: any) => q.id == qid).type === "singleselect" &&
      activeQuestionnaire.find((q: any) => q.id == qid).options.find((o: any) => o.id == oid)?.type != "manual"
    ) {
      console.log("Moving to next1");
      nextStep(updatedQuestionnaire);
    }
  };

  useEffect(() => {
    console.log(activeQuestionnaire);
  }, [activeQuestionnaire]);

  const [mustSpin, setMustSpin] = useState(false);
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  const [play] = useSound(sound);
  const [playAchievement] = useSound(achievement);

  const [selectedPrize, setSelectedPrize] = useState(-1);

  function getRandomNumber(min: any, max: any) {
    // Generates a random decimal between 0 (inclusive) and 1 (exclusive)
    const randomDecimal = Math.random();
    // Scale and shift the random decimal to fit the desired range
    return Math.floor(randomDecimal * (max - min + 1)) + min;
  }

  // Example usage: get a random whole number between 1 and 8
  useEffect(() => {
    if (currentStep == -1) {
      setShowConfetti(false);
      setSelectedPrize(-1);
    }
  }, [currentStep]);

  return (
    <div className="h-screen w-full bg-secondary relative flex flex-col items-center justify-center">
      <AnimatePresence mode="wait">
        {currentStep === -1 && (
          <motion.div
            key="step1"
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.4 }}
            className="w-screen h-screen flex flex-col items-center justify-center"
            onClick={() => {
              nextStep();
            }}
          >
            <VideoPlayer />
            {/* <img src={require("../../assets/WIN.png")} className="w-full overflow-x-hidden" /> */}
          </motion.div>
        )}
        {activeQuestionnaire
          .filter((q: any) => showQuestion(q))
          .map((q: any, index: any) => {
            if (currentStep == index)
              return (
                <motion.div
                  key={index.toString()}
                  variants={variants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.4 }}
                  className="w-full h-full flex flex-col items-center justify-center"
                >
                  <div className="flex flex-col px-12 py-8 m-4 rounded-xl bg-white  items-center  min-h-96 justify-around xl:min-h-[600px] max-w-[90%]">
                    <h3 className="font-semibold xl:text-2xl ">
                      {q.question}
                    </h3>
                    <div
                      key={"biggrid"}
                      className={`${q.layout == "grid"
                        ? `grid grid-cols-3`
                        : q.layout == "gridsmall"
                          ? `grid grid-cols-2`
                          : q.layout == "widegrid"
                            ? `grid grid-cols-4`
                            : `flex flex-col`
                        } h-full w-full gap-2 mt-5 items-center justify-center`}
                    >
                      {q.options?.map((option: any) => (
                        <div
                          key={option.id}
                          className={`${option.selected ? `bg-secondary/30 border-secondary` : `bg-white  border-lightgrey`
                            } 
                          flex flex-col  items-center justify-center  px-4 w-full
                          ${q.layout == "grid" ? `py-6 min-h-[150px] ` : ` py-2`}
                            h-full rounded-2xl border shadow-sm relative `}
                          onClick={() => {
                            console.log("CLICKED");
                            if (option.type == "manual" && q.type == "singleselect") {
                              console.log("IGNORED");
                            } else {
                              handleOptionSelection(q.id, option.id);
                            }
                          }}
                        >
                          {option.img && (
                            <img
                              src={require(`../../assets/${option.img}`)}
                              className={`w-full ${q.iconsLarge ? `max-w-28` : `max-w-12`} mb-3  rounded-lg`}
                            />
                          )}
                          <p key={option.id} className="text-center text-base md:text-[18px] 2xl:text-2xl ">
                            {option.label}
                          </p>

                          {option.selected && (
                            <img
                              src={require("../../assets/tick.png")}
                              className="w-6 overflow-x-hidden absolute top-2 right-2"
                            />
                          )}
                          {option.type == "manual" && (
                            <>
                              <textarea
                                className=" border border-lightgrey rounded-lg mt-2 text-base md:text-[16px] 2xl:text-2xl w-full font-montserrat h-16 text-start items-end p-1"
                                placeholder="Enter your response."
                                defaultValue={option.textResponse ?? ""}
                                value={option.textResponse ?? ""}
                                onChange={(e) => {
                                  e.preventDefault();
                                  console.log("onchange text area");
                                  handleOptionSelection(q.id, option.id, e.target.value);
                                }}
                              />
                              {q.type == "singleselect" && (
                                <button
                                  className="w-full bg-primary text-secondary p-1 mt-2 font-montserrat font-semibold text-lg rounded-lg shadow-md"
                                  onClick={() => {
                                    if (option.textResponse?.trim().length > 3) {
                                      nextStep();
                                    } else {
                                      toast.warning("Please enter a response to proceed.");
                                    }
                                  }}
                                >
                                  Next
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    {q.type === "multiselect" && (
                      <button
                        className="w-full bg-primary text-secondary p-2 mt-5 font-montserrat font-semibold text-2xl rounded-lg shadow-md"
                        onClick={() => {
                          nextStep();
                        }}
                      >
                        Next
                      </button>
                    )}
                    {q.type == "form" && (
                      <div className="flex flex-col pb-10 rounded-xl bg-white items-center justify-center">
                        <div
                          // component="form"

                          className="mt-1 bg-white rounded-lg"
                        >
                          <TextField
                            autoComplete="off"
                            required
                            label="Full Name"
                            name="name"
                            fullWidth
                            margin="none"
                            value={formData.name}
                            onChange={handleChange}
                            error={!!errors?.name}
                            helperText={errors?.name || " "}
                            InputProps={{ style: { fontFamily: "Montserrat" }, autoComplete: "off" }}
                            InputLabelProps={{ style: { fontFamily: "Montserrat" } }}
                            onClick={(event) => event.stopPropagation()} // Add this line
                          />

                          <TextField
                            autoComplete="off"
                            InputProps={{ style: { fontFamily: "Montserrat" }, autoComplete: "off" }}
                            InputLabelProps={{ style: { fontFamily: "Montserrat" } }}
                            required
                            label="Email"
                            name="email"
                            type="email"
                            fullWidth
                            margin="none"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!errors?.email}
                            helperText={errors?.email || " "}
                            onClick={(event) => event.stopPropagation()} // Add this line
                          />

                          <TextField
                            autoComplete="off"
                            InputProps={{ style: { fontFamily: "Montserrat" }, autoComplete: "off" }}
                            InputLabelProps={{ style: { fontFamily: "Montserrat" } }}
                            label="Company Name"
                            name="companyName"
                            required
                            fullWidth
                            margin="none"
                            value={formData.companyName}
                            error={!!errors?.companyName}
                            onChange={handleChange}
                            helperText={errors?.companyName || " "}
                            onClick={(event) => event.stopPropagation()} // Add this line
                          />

                          <div
                            className={`flex flex-col items-center justify-center my-3 p-3 ${errors.selectedVoucher ? `bg-red/40` : `bg-white`
                              }`}
                          >
                            <label className="ml-2 text-left w-full font-medium">
                              Please select which complimentary voucher you would prefer.
                            </label>

                            <div className={`flex flex-row items-center justify-center my-2 gap-2 rounded-md `}>
                              {vouchers.map((voucher: any) => {
                                return (
                                  <div
                                    key={voucher?.voucherTypeID}
                                    className={`p-3 relative flex flex-col items-center justify-center border rounded-md ${voucher.voucherTypeID == selectedVoucher?.voucherTypeID
                                      ? `bg-secondary/30 border-secondary`
                                      : `bg-white  border-lightgrey`
                                      } `}
                                    onClick={() => {
                                      setSelectedVoucher(voucher);
                                      // Clear error when the field is corrected
                                      if (errors.selectedVoucher) {
                                        setErrors({
                                          ...errors,
                                          selectedVoucher: null,
                                        });
                                      }
                                    }}
                                  >
                                    {voucher.img && (
                                      <img
                                        src={require(`../../assets/${voucher.img}`)}
                                        className="w-full max-w-12 mb-3  rounded-lg"
                                      />
                                    )}
                                    {voucher.voucherTypeID == selectedVoucher?.voucherTypeID ? (
                                      <img
                                        src={require("../../assets/tick.png")}
                                        className="w-6 overflow-x-hidden absolute top-2 right-2"
                                      />
                                    ) : null}
                                    <p className="text-center text-sm font-semibold ">{voucher.provider}</p>
                                    <p className="text-center text-sm ">{voucher.description}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="flex flex-col items-center justify-center my-5">
                            <div className="flex flex-row items-center justify-center my-2">
                              <input
                                type="checkbox"
                                value={formData.tcs}
                                onChange={handleChange}
                                name="tcs"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <label className="ml-2">
                                I accept the{" "}
                                <a
                                  onClick={() => {
                                    setShowModal(true);
                                  }}
                                >
                                  terms and conditions
                                </a>{" "}
                                of submitting this form.
                              </label>
                            </div>
                            {errors.tcs && <p className="ml-2 text-red">{errors.tcs}</p>}
                          </div>

                          <Button
                            onClick={(e) => {
                              console.log("handleSubmit");
                              handleSubmit(e);
                            }}
                            fullWidth
                            variant="contained"
                            disabled={submitting}
                            sx={{
                              mb: 2,
                              color: "#333",
                              backgroundColor: "#86eb85",
                              fontFamily: "Montserrat",
                              fontSize: 18,
                              fontWeight: "bold",
                              ":hover": {
                                backgroundColor: "#333",
                                color: "#86eb85",
                              },
                            }}
                          >
                            Submit Form
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </motion.div>
              );
          })}

        {currentStep == 100 &&
          (showPrize ? (
            <motion.div
              key="prize"
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.4 }}
              className="w-full h-full flex flex-col items-center justify-center relative"
            // onClick={() => {
            //   setCurrentStep(-1);
            // }}
            >
              <div
                className="w-screen h-screen  flex flex-row items-center justify-center"
                onClick={() => {
                  setShowPrize(false);
                  startAgain();
                }}
              >
                {prizes[selectedPrize].option == "Bag" ? (
                  <img src={require(`../../assets/BAG.png`)} className="w-full   rounded-lg" />
                ) : (
                  <img
                    src={require(`../../assets/${prizes[selectedPrize].imgPrefix}${selectedVoucher.voucherTypeID}.png`)}
                    className="w-full   rounded-lg"
                  />
                )}
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="spinner"
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.4 }}
              className="w-full h-full flex flex-col items-center justify-center relative p-4"
            // onClick={() => {
            //   setCurrentStep(-1);
            // }}
            >
              <div className="grid grid-cols-2 p-10 rounded-xl bg-white items-center justify-center">
                <div className="flex flex-row items-start justify-center px-3">
                  <div className="flex flex-col items-center justify-center">
                    <img src={require("../../assets/checked.png")} className="w-16 h-16 mb-10" />

                    <h3 className="text-3xl font-semibold text-center">
                      Thank you for your submission!</h3>
                    <h3 className="text-3xl font-[900] text-center">Spin the wheel to win a guaranteed prize!</h3>


                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">

                  <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={selectedPrize}
                    data={prizes}
                    backgroundColors={[
                      "#FF5964", //red
                      "#86EB00", //green

                      "#F7EC00", //dark yellow
                      "#51D6FF", //baby blue
                      "#FF5964", //red
                      "#86EB00", //green

                      "#F7EC00", //dark yellow

                      "#51D6FF", //baby blue

                      "#51D6FF", //baby blue

                      "#86EB00", //green

                      "#51D6FF", //baby blue

                      "#FE9920", //orange
                      "#FF5964", //red
                      "#F7EC00", //dark yellow

                      "#3CDEFC", //baby blue4
                    ]}
                    outerBorderColor="#888"
                    radiusLineColor="#888"
                    outerBorderWidth={3}
                    innerBorderWidth={3}
                    fontFamily={"Montserrat"}
                    perpendicularText={false}
                    spinDuration={0.6}
                    innerRadius={10}
                    onStopSpinning={() => {
                      setShowConfetti(true);
                      console.log(selectedVoucher);
                      setMustSpin(false);
                      if (prizes[selectedPrize].option == "Bag") {
                        play();
                      } else {
                        playAchievement();
                      }
                      setTimeout(() => {
                        setShowPrize(true);
                      }, 2000);
                      setTimeout(() => {
                        startAgain();
                      }, 60000);
                    }}
                  />
                  <button
                    className="font-montserrat font-semibold text-2xl bg-secondary text-primary hover:scale-[1.01] p-2 w-1/2 mt-5 rounded-lg shadow-md cursor-pointer"
                    onClick={() => {
                      const randomNumber = getRandomNumber(0, 7);
                      console.log(randomNumber);
                      setSelectedPrize(randomNumber);
                      storePrize(randomNumber);
                      setMustSpin(true);
                    }}
                    disabled={selectedPrize != -1 ? true : false}
                  >
                    Spin
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
      </AnimatePresence>
      <BackButton />
      {currentStep < 50 &&
        <StepsLeft />
      }

      <StartOver />
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
          <h2>Terms and Conditions</h2>
          INTRODUCTION <br />
          <br />
          Your privacy <br />
          <br />
          We take privacy, data security and your safety very seriously. We commit to: <br />
          - Never selling your personal data or sharing it with anyone that does not need it. <br />
          - Only using your personal information that relates to current or future Breez products, services or benefits.
          - Being transparent in terms of the data we collect. Putting you in control of your personal data that we
          store on your behalf.
          <br />
          <br /> Our terms and conditions
          <br />
          <br /> These terms and conditions together with our Privacy Policy (See Clause 9 below) set out the terms on
          which you may make use of our Application (as defined below). Please read these terms and conditions and
          privacy policy carefully before you start to use our Application. <br />
          <br />
          Agreement
          <br />
          <br /> By downloading or using our Application, you agree that you accept these terms and conditions,
          including the privacy policy, and that you agree to abide by them. If you do not agree to these terms and
          conditions, please refrain from using our Application. <br />
          <br />
          TERMS AND CONDITIONS DEFINITIONS AND INTERPRETATION <br />
          <br />
          The following terms and conditions (the “terms and conditions”) are a legal agreement between the customer
          “you”) and Breeze Technology Limited trading as Breez or Breez Insurance (“we”, “our”, “us”), a company with a
          registered office at 27 Stonor Road, London W14 8RZ and registered number 13916877. In addition to the
          capitalised terms defined elsewhere in these terms and conditions, the following terms shall have the meanings
          set out below. “Application” means the Application published by us for use on mobile telephones and
          smartphones, tablet computers, laptop computers, wearable device such as smartwatches. “Intellectual Property
          Rights” means (i) patents, pending patent applications, designs, trade marks and trade names (whether
          registered or unregistered), copyright and related rights, database rights, knowhow and confidential
          information; (ii) all other intellectual property rights and similar or equivalent rights anywhere in the
          world which currently exist or are recognised in the future; and (iii) applications, extensions and renewals
          in relation to any such rights. “Customer” means a customer of Breez Technology Limited who has the benefit of
          one or more of the benefits, services and products offered and facilitated by Breez. <br />
          <br />
          TERMS AND CONDITIONS <br />
          <br />
          In consideration of you agreeing to abide by these terms and conditions we will provide the Application to you
          in accordance with these terms and conditions. You agree to comply with any third party terms applicable to
          the downloading of our Application and in relation to the use of your device.
          <br />
          <br /> INFORMATION ABOUT US <br />
          <br />
          Our Application is operated by Breez Technology Limited, a company with a registered office at 27 Stonor Road,
          London, W14 *RZ and registered number 13916877.
          <br />
          <br /> YOUR QUESTIONS
          <br />
          <br /> If you have any questions about our Application, please email us using our secure web form which can be
          found at https://breez.insure/about/ <br />
          <br />
          LICENCE <br />
          <br />
          Subject to these terms and conditions, we grant to you a non-exclusive, non-transferable, royalty free
          licence, without the right to grant sub licences, solely for your personal use and solely for the duration of
          this agreement between us to install one copy of the Application onto a single approved device as set out
          below. If you own more than one approved device then you must download a separate copy of the Application to
          that device. <br />
          <br />
          USING OUR APPLICATION
          <br />
          <br /> By downloading our Application you warrant that: you are legally capable of entering into binding
          contracts; you are at least 18 years old; and you will abide by these terms and conditions in particular the
          prohibited uses at clause 9.
          <br />
          <br /> ACCESS TO OUR APPLICATION <br />
          <br />
          Access to our Application is permitted on a temporary basis, and we reserve the right to withdraw or amend the
          service we provide on our Application without notice (see below). We will not be liable if for any reason our
          Application is unavailable at any time or for any period. From time to time, we may restrict access to some
          parts of, or our entire Application. You are responsible for making all arrangements necessary for you to have
          access to our Application. You are responsible for controlling the access to and security settings of any
          device on which you install the Application. <br />
          <br />
          HOW THE CONTRACT BETWEEN YOU AND US IS FORMED
          <br />
          <br /> You enter into a contract with us in relation to your use of our Application when you download our
          Application onto your device and registering for a Breez account. This contract is distinct to your rights and
          obligations under the terms and conditions set out in full on the <br />
          <br />
          PROHIBITED USES OF APPLICATION <br />
          <br />
          You may use our Application only for lawful purposes. You may not use our Application:- in any way that
          breaches any applicable local, national or international law or regulation; in any way that is unlawful or
          fraudulent, or has any unlawful or fraudulent purpose or effect; for the purpose of harming or attempting to
          harm adults and/or minors in any way; to transmit, or procure the sending of, any unsolicited or unauthorised
          advertising or promotional material or any other form of similar solicitation (spam); <br />
          <br />
          INTELLECTUAL PROPERTY RIGHTS
          <br />
          <br />
          We are the owner or the licensee of all Intellectual Property Rights in our Application, and either own all
          Intellectual Property Rights in, or are licensed to publish, the material on it. Those works are protected by
          copyright laws and treaties around the world. All such rights are reserved. Nothing in these terms and
          conditions will be construed as conferring any licence of our Intellectual Property Rights except those
          licences expressly granted in these terms and conditions. Except as set out in this clause 10, you must not
          use, copy, store, download, sell, reproduce, redistribute, disclose, publish, rent, lease or lend the
          Application during or after the term of this agreement. You agree not to modify the Application, remove any
          copyright, trade mark or other Intellectual Property Rights from the Application or allow a third party to do
          so. You must not remove any copyright, trade mark or other Intellectual Property Rights legend from the
          Application. You agree not to make use of any of our trade marks or other Intellectual Property Rights in any
          manner unless we have given you express written permission to do so. You agree not to copy, duplicate, reverse
          engineer, reverse compile, disassemble, record or otherwise reproduce all or any part of the Application. You
          must not modify the paper or digital copies of any materials you have printed off or downloaded in any way,
          and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from
          any accompanying text. You must not use any part of our Application or materials on it for commercial purposes
          without obtaining a licence to do so from us and our licensors. If you print off, copy or download any part of
          our Application in breach of these terms and conditions, your right to use our Application will cease
          immediately and you must, at our option, return or destroy any copies of the materials you have made. <br />
          <br />
          RELIANCE ON INFORMATION POSTED
          <br />
          <br /> Commentary and other materials posted on our Application are not intended to amount to advice on which
          reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance
          placed on such materials by any user of our Application, or by anyone who may be informed of any of its
          contents. <br />
          <br />
          PRIVACY POLICY <br />
          <br />
          This privacy policy is complementary to, and should be read and understood together with, the general terms
          and conditions. The General Principles of our Privacy Policy This privacy policy covers how we treat your
          personal information collected electronically when you use the Site, register or apply online for any of our
          products or services, or when you contact us electronically. We respects your privacy and your personal
          information and for this reason, we take care to protect your personal information and to keep it
          confidential. When dealing with your personal information we apply the following: We will only disclose,
          collate and process (“use”) your personal information with your express written permission unless we are
          legally required to do so; We will not use your personal information for any other purpose, other than that
          which we disclosed to you, unless you give us your express written permission to do so, or unless we are
          permitted or required to do so by law <br />
          <br />
          What do we mean by Personal Information
          <br />
          <br /> Personal information refers to information that identifies or relates specifically to you, for example,
          your name, birth date or any information you use to register for the Application. How we collect your Personal
          Information Whenever you use the Application, complete an application form, contact us electronically, or use
          one of the products, services, facilities, tools or utilities offered by us on the Application, we will
          collect your personal information.
          <br />
          <br /> Why we collect and use Personal Information
          <br />
          <br /> In order to make your use of the Site and the products, services, facilities, tools or utilities
          offered on the Application as informative and successful as possible, it is necessary for us to find out
          exactly what you need and want. The following are some of the reasons (i.e. disclosed reasons) why we would
          collect your personal information: for us to process your instructions or requests; or for us to ensure that
          we meet your needs, we may collect and analyse your personal information and combine all the information that
          we have about you for research and statistical purposes. We may also use your personal information to
          personalise and tailor our services to meet your needs; or once we have collected and analysed your personal
          information, we may send you promotional material or details which we think may be of interest to you. to
          conduct market research; to conduct academic research which may be used to evaluate and improve our product
          offerings. You are advised that information may be shared with third parties such as academics and
          researchers. All such information collected will be kept strictly confidential and all data will be
          depersonalised to the extent possible and where appropriate. No personal information will be made available to
          a third party unless such third party has agreed to abide by strict confidentiality protocols. If we publish
          the results of this research, you will not be identified by name. Your privacy is important to us and we will
          therefore not sell, rent or provide your personal information to unauthorised third parties for their
          independent use, without your consent. If at any stage after you have given us your consent you no longer wish
          us to use or share your personal information, you may at any stage withdraw your consent. You accept that we
          may store your personal information outside of the region or country that you may submit or use it in. <br />
          <br />
          Protection of your Personal Information <br />
          <br />
          We value the information that you choose to provide and will take reasonable steps to protect your personal
          information from loss, misuse or unauthorised alteration. The information we have concerning our customers is
          stored in databases that have built-in safeguards to ensure the privacy and confidentiality of that
          information. When you use the products, services, features, tools or utilities provided by us on the
          Application, you may be given an access number, user name, password and/or personal identification number
          (PIN). You must always keep your user name, access card, password and/or PIN a secret and ensure that you do
          not disclose it to anyone. <br />
          <br />
          Correction of Personal Information <br />
          <br />
          If you ever want to update or correct any of your personal information held by us, you can e-mail or contact
          us directly through the Application Personal Information held by or disclosed by you or us to a third party
          Because we are not responsible for any representations or information or warranties or content on any third
          party website (including third party websites linked to this website, websites facilitated by us or websites
          that serve as social networks like Facebook or Twitter), we don’t exercise control over the privacy policies
          of these third parties and you should refer to the privacy policy of these third parties to see how they
          protect your privacy. We may enter into arrangements with its partners or other third party suppliers which
          will require us to disclose your personal information to these third parties for the purpose of transferring
          data to us from a device(s) that measures bodily functions or fluids. You hereby consent to us disclosing your
          personal information to these third parties for this purpose and you also consent to receiving data about
          yourself from these third parties. If at any time after you have given us your consent you no longer wish to
          disclose your personal information to these third parties, you may at any time withdraw your consent. Cookies
          and Online advertising We uses cookies. We use the word “cookie” to refer to information that is sent from the
          Site to your hard drive, where it is saved. In this way, the next time you use the Site, we will know who you
          are and that you have visited the Site before. We also collect information about how you use the website, your
          preferences and past browsing history. We engage third parties that help us deliver banner advertisements and
          other online communications. The third parties may collect and use information about our customers to help us
          understand the offers, promotions, and types of advertising that are most appealing to our customers. The
          personal information they collect is aggregated and cannot be linked to a person. Third party vendors,
          including Google and Facebook, show our ads on sites on the internet. Third party vendors, including Google
          and Facebook, use cookies to serve ads based on a user’s prior visits to our website. Users may opt out of
          Google and Facebook use of cookies by declining the use of cookies while using the Site. Changes to this
          Privacy Policy We may amend this privacy policy from time to time. We will give you notice of any material
          changes within a reasonable time, however, we recommend that you familiarise yourself with this privacy policy
          regularly. The current version of this privacy policy will govern the respective rights and obligations
          between you and us each time that you access and use the Application. Which laws apply to this Privacy Policy
          This privacy policy is governed by the laws of the United Kingdom, and you consent to the jurisdiction of the
          English courts in respect of any dispute which may arise out of or in connection with the formation,
          interpretation, substance or application of this privacy policy.
          <br />
          <br /> VIRUSES, HACKING AND OTHER OFFENCES <br />
          <br />
          You must not misuse our Application by knowingly introducing viruses, trojans, worms, logic bombs or other
          material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to,
          interfere with, damage or disrupt our Application, the server, equipment or network on which our Application
          is stored, any software used in the provision of our Application or any server, computer or database connected
          to our Application. You must not attack our Application via a denial of service attack or a distributed denial
          of service attack. By breaching this clause 13, you would commit a criminal offence under the Computer Misuse
          Act 1990. We will report any such breach to the relevant law enforcement authorities and we will cooperate
          with those authorities by disclosing your identity to them. In the event of such a breach, your right to use
          our Application will cease immediately. We will not be liable for any loss or damage caused by a distributed
          denial of service attack, viruses or other technologically harmful material that may infect your computer
          equipment, computer programs, data or other proprietary material due to your use of our Application or to your
          downloading of any material posted on it, or on any website linked to it. You must not reproduce duplicate,
          copy or re-sell any part of our Application in contravention of the provisions of these terms and conditions.
          <br />
          <br />
          SUSPENSION AND TERMINATION
          <br />
          <br /> We will determine, in our discretion, whether there has been a breach of these terms and conditions.
          When a breach of these terms and conditions has occurred, we may take such action as we deem appropriate
          including terminating this agreement. If this agreement is terminated for any reason you will delete our
          Application from your device and we have the right to block your access to the Application. Failure to comply
          with these terms and conditions constitutes a material breach of these terms and conditions upon which you are
          permitted to use our Application, and may result in our taking all or any of the following actions:-
          immediate, temporary or permanent withdrawal of your right to use our Application; immediate, temporary or
          permanent removal of any posting or material uploaded by you to our Application; issue of a warning to you;
          legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited
          to, reasonable administrative and legal costs) resulting from the breach; further legal action against you;
          disclosure of such information to law enforcement authorities as we reasonably feel is necessary. <br />
          <br />
          OUR LIABILITY
          <br />
          <br /> The Application is provided free of charge and therefore is provided to you on your own behalf without
          any guarantees, conditions or warranties as to its accuracy (whether express or implied). To the extent
          permitted by law, we, other customers of our group of companies and third parties connected to us hereby
          expressly exclude liability for:- all conditions, warranties and other terms which might otherwise be implied
          by statute, common law or the law of equity; loss of profits; any direct, indirect or consequential loss or
          damage incurred by you in connection with our Application or in connection with the use, inability to use, or
          results of the use of our Application, any websites linked to it and any materials posted on it. This does not
          affect our liability for death or personal injury arising from our negligence, nor our liability for
          fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which
          cannot be excluded or limited under applicable law. Your sole right and remedy in the event of any defect in
          the Application or its operation, to the exclusion of all other remedies, will be to terminate the licences
          described in these terms and conditions and to delete the Application from your device. <br />
          <br />
          OUR APPLICATION CHANGES REGULARLY
          <br />
          <br /> We aim to update our Application regularly, and may change the content at any time. If the need arises,
          we may suspend access to our Application, or close it indefinitely. Any of the material on our Application may
          be out of date at any given time, and we are under no obligation to update such material. <br />
          <br />
          OUR RIGHT TO VARY THESE TERMS AND CONDITIONS <br />
          <br />
          We may revise these terms and conditions at any time by amending this page. Please check this page from time
          to time which can be found on the Application login page. Material changes to these terms and conditions will
          be notified to you. You will be subject to the policies and terms and conditions in force at the time that you
          download our Application and then at the time that an Active Reward is generated, unless any change to those
          policies or terms and conditions is required to be made by law or governmental authority. <br />
          <br />
          JURISDICTION AND APPLICABLE LAW <br />
          <br />
          The English courts will have exclusive jurisdiction over any claim or dispute arising from, or related to, the
          Application. These terms and conditions and any dispute or claim arising out of or in connection with them or
          their subject matter or formation (including noncontractual disputes or claims) shall be governed by and
          construed in accordance with the law of England and Wales.
          <br />
          <br /> ASSIGNMENT <br />
          <br />
          You may not sub-license, assign or transfer in any way any of your rights, liabilities and/or obligations
          under these terms and conditions on a temporary or permanent basis to any third party without our prior
          written consent. <br />
          <br />
          SEVERABILITY <br />
          <br />
          If any provision of these terms and conditions (or part of any provision) is found by any court or other
          authority of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions will remain
          unaffected and in force. <br />
          <br />
          ENTIRE AGREEMENT <br />
          <br />
          These terms and conditions and any document expressly referred to in them constitute the whole agreement
          between us and supersede all previous discussions, correspondence, negotiations, previous arrangement,
          understanding or agreement between us relating to the subject matter of these terms and conditions. We each
          acknowledge that, in entering into these terms and conditions, neither of us relies on, or will have any
          remedies in respect of, any representation or warranty (whether made innocently or negligently) that is not
          set out in these terms and conditions or the documents referred to in them. Each of us agrees that our only
          liability in respect of those representations and warranties that are set out in these terms and conditions
          (whether made innocently or negligently) will be for breach of contract. Nothing in this clause limits or
          excludes any liability for fraud.{" "}
        </Paper>
      </Modal>
      {showConfetti && <Confetti width={width} height={height} />}
    </div>
  );
};

export default Form;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  paper: {
    width: "80%",
    height: "80%",
    overflowY: "auto", // Enable scrolling on overflow
    padding: "15px",
  },
}));
